<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-row :gutter='24' >
      <a-col :sm='12' :md='6' :xl='8' :style="{ marginBottom: '24px' }">
        <a-card :loading="loading">
          <div class="card_content">
            <a-row>
              <a-col :span="12"><span class="card_title">订单积分</span></a-col>
              <a-col :span="12"><span class="card_title">兑换积分</span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="12"><span class="color-red">{{ dashboard.cash }}</span></a-col>
              <a-col :span="12"><span class="color-red">{{dashboard.exchangeIntegral}}</span></a-col>
            </a-row>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='orderId'>
        <a-input v-model='form.orderId' style="width: 200px" placeholder="订单号" />
      </a-form-model-item>

      <a-form-model-item prop='userName'>
        <a-input v-model='form.userName' style="width: 200px" placeholder="购买人" />
      </a-form-model-item>

      <a-form-model-item prop='spreaderName'>
        <a-input v-model='form.spreaderName' style="width: 200px" placeholder="获得人" />
      </a-form-model-item>

      <a-form-model-item prop='servicePresidentName'>
        <a-input v-model='form.servicePresidentName' style="width: 200px" placeholder="分公司名称" />
      </a-form-model-item>

      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>

      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator'>
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>

      <s-table ref='table' rowKey='id' :columns='columns' :scroll='{ x: "100%"}' :data='loadData'>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a @click='handleEdit(record)'>编辑</a>
            <span>
                  <a-divider type='vertical' />
                  <a @click='handleDelete(record)'>删除</a>
            </span>
          </template>
        </span>
        <span slot='settleType' slot-scope='text'>
          <template>
            {{getSettleType(text)}}
          </template>
        </span>

        <span slot='orderId' slot-scope='text, record'>
          <template>
            <a @click='openDetail(record)'>{{ text }}</a>
          </template>
        </span>

        <span slot='buyType' slot-scope='text, record'>
          <template>
            {{getBuyType(text)}}
          </template>
        </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import {queryCashList, queryHeadCash} from '@/api/cash'
import moment from "moment";

const columns = [
  {
    width: 120,
    title: '订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 150,
    title: '下单时间',
    dataIndex: 'orderCreateTime',
    scopedSlots: { customRender: 'orderCreateTime' }
  },
  {
    width: 100,
    title: '积分类型',
    dataIndex: 'settleType',
    scopedSlots: { customRender: 'settleType' }
  },
  {
    width: 80,
    title: '订单金额',
    dataIndex: 'orderAmount',
    scopedSlots: { customRender: 'orderAmount' }
  },
  {
    width: 100,
    title: '购买人',
    dataIndex: 'username',
    scopedSlots: { customRender: 'username' }
  },
  {
    width: 100,
    title: '获得积分',
    dataIndex: 'shareIntegral',
    scopedSlots: { customRender: 'shareIntegral' }
  },
  {
    width: 100,
    title: '推荐人',
    dataIndex: 'spreaderName',
    scopedSlots: { customRender: 'spreaderName' }
  },
  {
    width: 100,
    title: '获得积分',
    dataIndex: 'spreaderCash',
    scopedSlots: { customRender: 'spreaderCash' }
  },
  // {
  //   width: 100,
  //   title: '收款商户',
  //   dataIndex: 'merchantName',
  //   scopedSlots: { customRender: 'merchantName' }
  // },
  {
    width: 100,
    title: '兑换积分',
    dataIndex: 'exchangeIntegral',
    scopedSlots: { customRender: 'exchangeIntegral' }
  },
  {
    width: 100,
    title: '方式',
    dataIndex: 'buyType',
    scopedSlots: { customRender: 'buyType' }
  },

  {
    width: 200,
    title: '分公司简称',
    dataIndex: 'areaName'
  }
]

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'OperatorList',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      loading:true,
      dashboard: {}, //统计数据
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        start: moment(baseStart).startOf('day').format(formatType ),
        end: moment(baseEnd).endOf('day').format(formatType),
      },
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        let params = Object.assign(parameter, this.queryParam,this.form);
        return queryCashList(params)
          .then(datum => {
            // debugger;
            return datum
          })
      }
    }
  },
  created() {
      this.getHeaderData();
  },
  methods: {
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    getSettleType(text){
      if(text =='1'){
        return '分享';
      }else if (text =='2'){
        return '分享+活动'
      }
    },
    getBuyType(text){
      if(text =='0'){
        return '首次';
      }else if (text =='1'){
        return '复购'
      }
    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
      this.loading=true;
      this.getHeaderData();
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = moment(baseStart).startOf('day').format(this.formatType);
      this.form.end = moment(baseEnd).endOf('day').format(this.formatType);
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleView(url) {
      window.open(url, '_blank')
    },
    openDetail(record){
      this.$router.push({ path: '/points/order/Detail', query: { id: record.orderId } })
    },
    //表头积分显示
    getHeaderData(){
      let params = Object.assign({}, this.form);

      queryHeadCash(params).then(result =>{
        if(result){
          result.cash = result.cash||0;
          result.exchangeIntegral = result.exchangeIntegral||0;
          this.dashboard = result;
        }else{
          result = [];
          result.cash = 0;
          result.exchangeIntegral = 0;
          this.dashboard = result;
        }

        this.loading = false;
      });
    },
  }
}
</script>
